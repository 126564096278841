<template>
	<div class="page">
		<headerBlock
			v-if="headerBlock"
			:content="{
				title: pageTitle,
				text: headerBlock.text,
				fileURL: headerBlock.fileURL,
				pageColor: pageColor,
			}"
		/>
		<headerBlock v-else :content="{ title: pageTitle }" />
		<div v-for="item in content.sort((a, b) => a.orderBy - b.orderBy)" :key="item.id">
			<!-- <p>{{ item }}</p> -->
			<textVideoBlock
				v-if="item.blockType === 'vidoeBlock'"
				:content="{
					heading: item.displayName,
					text: item.descriptionText,
					buttons: item.buttons,
					videoURL: item.videos[0].mediaURL,
				}"
			/>
			<hotspotBlock
				v-if="item.blockType === 'Expanded'"
				:content="{
					heading: item.displayName,
					content: item.descriptionText,
					buttons: item.buttons,
					images: item.images,
					videos: item.videos,
				}"
			/>
			<textBlock
				v-if="item.blockType === 'Text'"
				:content="{
					heading: item.displayName,
					subheading: item.displaySub,
					text: item.blockText,
					buttonText: item.actionValue,
					buttonLink: item.actionValue,
				}"
			/>
			<pdfBlock
				v-if="item.blockType === 'pdfBlock'"
				:content="{
					heading: item.displayName,
					text: item.descriptionText,
					buttons: item.buttons,
					pdfURL: item.pdfFile,
					pdfImage: item.pdfImage,
				}"
			/>
			<sliderBlock
				v-if="item.blockType === 'Slider'"
				:content="{
					heading: item.blockTitle,
				}"
			/>
		</div>
		<sliderBlock
			:content="{
				heading: 'HIVE Zones',
			}"
		/>
	</div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";

import headerBlock from "@/components/blocks/header";
import textVideoBlock from "@/components/blocks/textVideo";
import hotspotBlock from "@/components/blocks/hotspot";
import textBlock from "@/components/blocks/text";
import sliderBlock from "@/components/blocks/slider";
import pdfBlock from "@/components/blocks/pdfBlock";

// import contentBlock from "@/components/blocks/content";
// import textImageBlock from "@/components/blocks/textImage";

export default {
	name: "App",
	components: {
		headerBlock,
		textVideoBlock,
		hotspotBlock,
		textBlock,
		sliderBlock,
		pdfBlock,

		// contentBlock,
		// textImageBlock,
	},
	setup() {
		const store = useStore();
		/* ----- DATA -------------------- */
		const pageTitle = ref(computed(() => store.state.pageContent.pageTitle));
		const headerBlock = ref(computed(() => store.state.pageContent.header));
		const content = ref(computed(() => store.state.pageContent.data));
		const navigation = ref(computed(() => store.state.navigation.menu));
		const pageColor = ref(computed(() => store.state.pageContent.pageColour));
		const singleSlides = ref([]);

		onMounted(() => {
			for (let i = 0; i < navigation.value.length; i++) {
				if (navigation.value[i].slider) {
					singleSlides.value.push(navigation.value[i]);
				}
			}
		});

		useHead({
			title: pageTitle.value + store.state.siteTitle,
			siteTitle: " - Hive UPS",
			meta: [
				{
					name: `description`,
					content: "hello",
				},
			],
		});

		return {
			pageTitle,
			headerBlock,
			content,
			pageColor,
		};
	},
};
</script>

<style lang="scss">
.app .page div:nth-child(even) .hotspot-Block {
	background-color: var(--background-alt);
}
</style>
