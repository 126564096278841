<template>
	<div class="textPDF-Block" :class="{ animate: animate }" :id="'textPDF-Block-' + id" v-if="loaded">
		<div class="container">
			<div class="row">
				<div class="col-md-6 ml-auto">
					<h2>{{ content.heading }}</h2>
					<p v-html="content.text"></p>
					<a :href="content.buttonLink" v-if="content.buttons.length > 0" target="_blank">
						<button>{{ content.button }}</button>
					</a>
				</div>
			</div>
		</div>
		<div class="image">
			<img v-if="content.imageType" :src="content.imageURL" alt="" />
			<a v-else :href="'https://cdn.hivebyups.com/ups/StreamingAssets/' + content.pdfURL" target="_blank">
				<img :src="'https://cdn.hivebyups.com/ups/StreamingAssets/' + content.pdfImage" alt="" />
			</a>
		</div>
	</div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
	name: "Text Image Block",
	props: {
		content: Object,
	},
	setup() {
		/* ----- Data -------------------- */
		const loaded = ref(false);
		const animate = ref(false);
		const id = Math.floor(Math.random() * (999 - 1) + 1);

		/* ----- Methods -------------------- */
		const checkAnimation = () => {
			if (
				document.getElementById("textPDF-Block-" + id).offsetTop - window.screen.height / 2 <=
				window.scrollY
			) {
				animate.value = true;
			}
		};

		/* ----- onMounted -------------------- */
		onMounted(() => {
			loaded.value = true;

			document.addEventListener("DOMContentLoaded", () => {
				checkAnimation();
			});
			document.addEventListener("scroll", () => {
				checkAnimation();
			});
		});

		return {
			loaded,
			animate,
			id,
			checkAnimation,
		};
	},
};
</script>

<style lang="scss">
.textPDF-Block {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	height: calc(56.25vw / 2);
	background-color: var(--background-dark);
	overflow: hidden;

	h2 {
		font-size: 40px;
		font-weight: 700;
		color: var(--background);
		margin: 0;
		margin-bottom: 20px;

		transform: translateY(50px);
		opacity: 0;
	}
	p {
		font-size: 18px;
		font-weight: 400;
		color: var(--background);
		margin: 0;
		margin-bottom: 45px;

		transform: translateY(35px);
		opacity: 0;

		a {
			font-size: 22px;
			font-weight: 700;
			color: var(--background);
			background-color: var(--cta);
			border: none;
			border-radius: 30px;
			padding: 10px 45px;
			transition: 0.6s ease;
			text-decoration: none;
		}
		a:hover {
			color: var(--cta);
			background-color: var(--background);
		}
	}
	button {
		transform: translateY(35px);
		opacity: 0;
	}
	.image {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: calc(50% - 15px);
		height: 100%;
		background-color: var(--cta);
		overflow: hidden;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: top center;

			opacity: 0;
		}
	}
	.image:after {
		content: "";
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: var(--hive);
		z-index: 2;
	}
}
.textPDF-Block.animate {
	h2 {
		animation-name: h2;
		animation-iteration-count: 1;
		animation-duration: 1s;
		animation-delay: 0;
		animation-fill-mode: forwards;
	}
	p {
		animation-name: p;
		animation-iteration-count: 1;
		animation-duration: 1s;
		animation-delay: 0.3s;
		animation-fill-mode: forwards;
	}
	button {
		animation-name: button;
		animation-iteration-count: 1;
		animation-duration: 1s;
		animation-delay: 0.6s;
		animation-fill-mode: forwards;
	}
	.image {
		img {
			animation-name: img;
			animation-iteration-count: 1;
			animation-duration: 1s;
			animation-delay: 0s;
			animation-fill-mode: forwards;
		}
	}
	.image:after {
		animation-name: image;
		animation-iteration-count: 1;
		animation-duration: 2s;
		animation-delay: 0s;
		animation-fill-mode: forwards;
	}
}

/* ----- Animations -------------------- */
@keyframes h2 {
	0% {
		transform: translateY(50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes p {
	0% {
		transform: translateY(35px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes button {
	0% {
		transform: translateY(35px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes image {
	0% {
		top: 100%;
	}
	50% {
		top: 0;
	}
	100% {
		top: -100%;
	}
}
@keyframes img {
	0% {
		opacity: 0;
	}
	99% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@media only screen and (max-width: 768px) {
	.textImage-Block {
		height: auto;
		padding-top: calc(56.25% + 60px);
		padding-bottom: 60px;
		.image {
			width: 100%;
			height: calc(100% - 56.6%);
		}
		button {
			width: 100%;
			text-align: center;
		}
	}
}
</style>
